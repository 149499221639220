<template>
  <v-toolbar
    prominent
    class="bg-white border-bottom-menu"
    dense
    fixed
  >
    <v-app-bar-nav-icon aria-label="Menu de navigation" @click.stop="$emit('showNavigation')" />
    <v-toolbar-title>
      <slot name="title" />
    </v-toolbar-title>

    <v-spacer class="d-none d-md-block" />
    <slot name="rightMenu" />
  </v-toolbar>
</template>
<script lang="ts">
export default {
    name : "ToolbarMenu",
    emits: ['showNavigation'],
}
</script>
