<script lang="ts">
import tablePageInit from '~/packages/datakode/nuxt-datakode-utils/utils/tablePageInit.vue'

export default {
    name: 'ListOrganismes',
    extends: tablePageInit,
    setup() {
        useHead({
            title: 'Organismes',
            meta: [
                { name: 'description', content: 'Gestion des organismes' },
            ],
        })
        definePageMeta({
            middleware: ['auth'],
        })
    },
    data() {
        return {
            types: [],
            permissionName: 'organization',
            requestName: 'organizations',
            defaultUrl: '/organismes',
            translationName: 'organismes',
            valueAsViewLink: [],
            columns: [
                { dataKey: 'name', template: [this.showCLEdate] },
                { dataKey: 'type', transform: [this.toTranslate], template: [this.isChips] },
                { dataKey: 'users_count' },
                { dataKey: 'communes_count' },
            ],
            initSort: {
                key: 'name',
                order: 'asc',
            },
            filtersOverload: ['communes.id', 'name', 'type'],
        }
    },
    methods: {
        getOrgaTypes() {
            this.$api.getData({
                url: `/organizations/get-types`,
            }).then((res) => {
                this.types = res
            })
        },
        showCLEdate(key, value, template, item) {
            let html = template
            if (item.cle) {
                html += ` <br/>
                        <v-chip size="x-small" class="mb-1">
                            Date de CLE : ${this.$formatDate(item.cle.date_cle)}
                        </v-chip>
                    `
            }
            return html
        },
    },
}
</script>

<template>
    <div id="organismes">
        <div class="mt-6 mb-3">
            <ListActionsButtonsAdd
                ref="add"
                :setup-dialog="true"
                class="mr-3"
                :permission-name="permissionName" :request-name="requestName"
                :translation-name="translationName"
                :default-url="defaultUrl"
                @is-open="$event ? getOrgaTypes() : createData = {}"
            >
                <template #selection>
                    <v-select
                        v-model="createData.type"
                        variant="outlined"
                        density="compact"
                        no-data-text="Aucun choix disponible"
                        label="Choisissez le type de votre organisme"
                        :items="types"
                    >
                        <template #item="{ props, item }">
                            <v-list-item v-bind="props">
                                <v-list-item-title class="select-options">
                                    <v-chip v-if="isChips" :color="$getColor(`${translationName}.type.${item.value}`)">
                                        {{ $translate(`${translationName}.type.${item.value}`) }}
                                    </v-chip>
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                        <!-- TEMPLATE IN SELECT INPUT -->
                        <template #selection="{ item }">
                            <v-chip :color="$getColor(`${translationName}.type.${item.value}`)">
                                {{ $translate(`${translationName}.type.${item.value}`) }}
                            </v-chip>
                        </template>
                    </v-select>
                </template>
                <template #validation>
                    <v-btn v-if="!createData.type" color="success" disabled>
                        Valider
                    </v-btn>
                    <NuxtLink v-else :to="getCreateRequest()">
                        <v-btn color="success" @click="$refs.add.closeModal(); createData = {}">
                            Valider
                        </v-btn>
                    </NuxtLink>
                </template>
            </ListActionsButtonsAdd>

            <ListActionsButtonsExport
                :query="$refs?.filters?.query"
                :request-name="requestName"
                :translation-name="translationName"
                :exportable="exportable"
            />
        </div>

        <ListFilters
            v-if="filters"
            ref="filters"
            :permission-name="permissionName"
            :filters="filters"
            :translation-name="translationName"
            :filters-overload="filtersOverload"
            @load-data="loadData()"
            @reset-pagination="$refs.pagination.resetPagination()"
        />
        <v-card class="pa-5">
            <ListTable
                ref="table"
                :permission-skip="true"
                :value-as-view-link="valueAsViewLink"
                :permission-name="permissionName"
                :translation-name="translationName"
                :columns="columns"
                :sortable="sortable"
                :default-url="defaultUrl"
                :loading="loading"
                :data="data"
                :init-sort="initSort"
                @reset-pagination="$refs.pagination.resetPagination()"
                @load-data="loadData()"
                @delete-data="deleteData($event)"
            />
            <div>
                <ListPagination ref="pagination" :total="total" :loading="loading" :last-page="lastPage" @load-data="loadData" />
            </div>
        </v-card>

        <NuxtPage
            :request-name="requestName" :default-url="defaultUrl"
            :translation-name="translationName" :permission-name="permissionName"
            @load-data="loadData()"
            @delete-data="deleteData($event)"
        />
    </div>
</template>
