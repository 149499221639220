import { useUserStore } from '~/store'

export default defineNuxtRouteMiddleware(async () => {
    const userStore = useUserStore()
    const currentUser = userStore.currentUser
    const access_token = useCookie<string>('access_token')

    if (access_token) {
        const res = await userStore.fetchUser()
        if (res?.k)
            return navigateTo('/')
    }

    if (currentUser.k)
        return navigateTo('/')
})
