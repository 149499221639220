<script lang="ts">
export default {
    name: 'NavigationSidebar',
    props: {
        rail: { type: Boolean, required: true },
    },
    data: () => ({
        hover: false,
    }),
    computed: {
        isLoggedIn() {
            return this.$currentUser().k
        },
    },
    methods: {
    },
}
</script>

<template>
    <div @mouseover="hover = true" @mouseleave="hover = false">
        <!-- <v-list-item prepend-avatar="https://deci.sdis84.fr/images/DECI/horizontal-couleur.jpg" -->
        <v-list-item nav>
            <div class="w-100 text-center">
                <img v-if="!rail || hover" src="@/assets/images/logo-cleaned.png" alt="Logo Serpic" width="100">
                <img v-else src="@/assets/images/logo-alone.png" alt="Logo Serpic" width="30" class="py-2">
            </div>
        </v-list-item>

        <v-divider />

        <v-list density="compact" nav>
            <NuxtLink to="/">
                <v-list-item prepend-icon="mdi mdi-application-outline" value="home">
                    <v-list-item-title>Accueil</v-list-item-title>
                </v-list-item>
            </NuxtLink>

            <NuxtLink v-if="$permissionCheck(`dashboard_page`)" to="/tableau-de-bord">
                <v-list-item prepend-icon="mdi-view-dashboard" value="dashboard">
                    <v-list-item-title>Tableau de bord</v-list-item-title>
                </v-list-item>
            </NuxtLink>

            <NuxtLink v-if="$permissionCheck(`declaration_page`)" to="/declarations">
                <v-list-item prepend-icon="mdi-file-multiple" value="declarations">
                    <v-list-item-title>Déclarations</v-list-item-title>
                </v-list-item>
            </NuxtLink>

            <NuxtLink v-if="$permissionCheck(`changeLog_page`)" to="/historique">
                <v-list-item prepend-icon="mdi-history" value="historique">
                    <v-list-item-title>Historique</v-list-item-title>
                </v-list-item>
            </NuxtLink>

            <NuxtLink v-if="$permissionCheck(`declaration_page`)" to="/carte-brulages">
                <v-list-item prepend-icon="mdi-map" value="carte-brulages">
                    <v-list-item-title>Carte des brûlages</v-list-item-title>
                </v-list-item>
            </NuxtLink>

            <!-- <div v-if="isLoggedIn && $isAdmin()"> -->
            <div>
                <v-list-subheader v-if="$hasOnePermissionCheck([`organization_page`, 'user_page'])" class="text-uppercase mt-5">
                    Administration
                </v-list-subheader>
                <NuxtLink v-if="$permissionCheck(`organization_page`)" to="/organismes">
                    <v-list-item prepend-icon="mdi mdi-city-switch" value="organismes">
                        <v-list-item-title>Organismes</v-list-item-title>
                    </v-list-item>
                </NuxtLink>
                <NuxtLink v-if="$permissionCheck(`user_page`)" to="/utilisateurs">
                    <v-list-item prepend-icon="mdi mdi-account-multiple" value="utilisateurs">
                        <v-list-item-title>Utilisateurs</v-list-item-title>
                    </v-list-item>
                </NuxtLink>
                <NuxtLink v-if="$permissionCheck(`configuration_index`)" to="/configuration">
                    <v-list-item prepend-icon="mdi mdi-cog" value="configuration">
                        <v-list-item-title>Paramètres</v-list-item-title>
                    </v-list-item>
                </NuxtLink>
            </div>
        </v-list>
    </div>
</template>
