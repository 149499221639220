import { useUserStore } from '~/store/index'

export default defineNuxtPlugin( () => {

    return {
        provide: {
            currentUser: () => {
                const userStore = useUserStore()
                return userStore.currentUser
            },
            permissionCheck : (permissionsName:string) => {
                const userStore = useUserStore()
                const currentUser = userStore.currentUser
                return currentUser && currentUser.permissions ? currentUser.permissions.includes(permissionsName) : false
            },
            hasOnePermissionCheck : (permissions:Array<any>) => {
                const userStore = useUserStore()
                const currentUser = userStore.currentUser
                const hasPermission = permissions.map( perm => currentUser.permissions ? currentUser.permissions.includes(perm) : false)
                return hasPermission.includes(true)
            },
            isAdmin : () => {
                const userStore = useUserStore()
                const currentUser = userStore.currentUser
                return currentUser.role ? currentUser.role === "admin" : false
            },
            // isManager : () => {
            //     const userStore = useUserStore()
            //     const currentUser = userStore.currentUser
            //     return currentUser.role ? currentUser.role === "manager" || nuxtApp.$isAdmin() : false
            // },
            // isUser : () => {
            //     const userStore = useUserStore()
            //     const currentUser = userStore.currentUser
            //     return currentUser.role ? currentUser.role === "user" || nuxtApp.$isAdmin() || nuxtApp.$isManager() : false
            // },
        }
    }
})
