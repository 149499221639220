import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{

    // keep scroll position when route change
    scrollBehavior(to, from, savedPosition) {
        let top = 0
        // check if route has changed
        if (to.matched && from.matched && to.matched[0]?.path === from.matched[0]?.path)
            top = window.scrollY

        if (savedPosition) {
            return savedPosition
        }
        else {
            return {
                top,
            }
        }
    },
}
