import type { ThemeDefinition } from 'vuetify'
import { createVuetify } from 'vuetify'

// import {aliases, mdi} from "vuetify/lib/iconsets/mdi";
import '@mdi/font/css/materialdesignicons.css'

// import fr from "vuetify/locale/fr";
import * as labsComponents from 'vuetify/labs/components'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const myCustomLightTheme: ThemeDefinition = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        surface: '#FFFFFF',
        light: '#f2f2f2',
        // primary: '#149E53',
        primary: '#0C6434',
        dark: '#363636',
        // secondary: '#BD7C3C',
        secondary: '#784F26',
        // danger: '#F24405',
        danger: '#AE1409',
        error: '#B00020',
        // info: '#2196F3',
        info: '#08599B',
        // success: '#038C4C',
        success: '#036336',
        warning: '#F26716',
    },
}

export default defineNuxtPlugin((nuxt) => {
    const vuetify = createVuetify({
        ssr: true,
        // components,
        components: {
            ...components,
            ...labsComponents,
        },
        directives,

        theme: {
            defaultTheme: 'myCustomLightTheme',
            themes: {
                myCustomLightTheme,
            },
        },
        icons: {
            defaultSet: 'mdi',
        },

    })
    nuxt.vueApp.use(vuetify)
})
