<script lang="ts">
export default {
    name: 'EmptyLayout',
    data: () => ({
        loading: true,
    }),
    mounted() {
        this.loading = false
    },
}
</script>

<template>
    <div>
        <client-only>
            <slot />
            <Toaster ref="toaster" />
        </client-only>
    </div>
</template>
