import { default as iframerOnfBYfsZQMeta } from "/builds/datakode/serpic2/nuxt/pages/carte-brulages/iframe.vue?macro=true";
import { default as indexHg2twtvVMXMeta } from "/builds/datakode/serpic2/nuxt/pages/carte-brulages/index.vue?macro=true";
import { default as configurationYFGWes1JRwMeta } from "/builds/datakode/serpic2/nuxt/pages/configuration.vue?macro=true";
import { default as indexESEyfMg2onMeta } from "/builds/datakode/serpic2/nuxt/pages/connexion/index.vue?macro=true";
import { default as _91token_93HO6tyO21ihMeta } from "/builds/datakode/serpic2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue?macro=true";
import { default as indexveMCc02E0PMeta } from "/builds/datakode/serpic2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue?macro=true";
import { default as mot_45de_45passe_45oublieb6nHlAuNEhMeta } from "/builds/datakode/serpic2/nuxt/pages/connexion/mot-de-passe-oublie.vue?macro=true";
import { default as formulairekyem4HkSd1Meta } from "/builds/datakode/serpic2/nuxt/pages/declarations/[k]/formulaire.vue?macro=true";
import { default as indexUV6oxKQqcxMeta } from "/builds/datakode/serpic2/nuxt/pages/declarations/[k]/index.vue?macro=true";
import { default as _91k_93a0uhLlgqhqMeta } from "/builds/datakode/serpic2/nuxt/pages/declarations/[k].vue?macro=true";
import { default as declarationsxsY5LC4FbmMeta } from "/builds/datakode/serpic2/nuxt/pages/declarations.vue?macro=true";
import { default as erreuriHtTpgwxPLMeta } from "/builds/datakode/serpic2/nuxt/pages/erreur.vue?macro=true";
import { default as historique9pRQ84V1oAMeta } from "/builds/datakode/serpic2/nuxt/pages/historique.vue?macro=true";
import { default as homepagecAVS8RniayMeta } from "/builds/datakode/serpic2/nuxt/pages/homepage.vue?macro=true";
import { default as index5FCXZ1cWPjMeta } from "/builds/datakode/serpic2/nuxt/pages/index.vue?macro=true";
import { default as legal_45noticesHnV02jHqU9Meta } from "/builds/datakode/serpic2/nuxt/pages/legal-notices.vue?macro=true";
import { default as formulairenqFMDquk7IMeta } from "/builds/datakode/serpic2/nuxt/pages/organismes/[k]/formulaire.vue?macro=true";
import { default as indexT1p4lQpxxfMeta } from "/builds/datakode/serpic2/nuxt/pages/organismes/[k]/index.vue?macro=true";
import { default as _91k_93hpJIL3OmVIMeta } from "/builds/datakode/serpic2/nuxt/pages/organismes/[k].vue?macro=true";
import { default as organismes5wjZrvMLOqMeta } from "/builds/datakode/serpic2/nuxt/pages/organismes.vue?macro=true";
import { default as index8PAzT4CtYmMeta } from "/builds/datakode/serpic2/nuxt/pages/profil/index.vue?macro=true";
import { default as organismeolMaD2bFCAMeta } from "/builds/datakode/serpic2/nuxt/pages/profil/organisme.vue?macro=true";
import { default as profilrK8cm1yOilMeta } from "/builds/datakode/serpic2/nuxt/pages/profil.vue?macro=true";
import { default as tableau_45de_45bord6iZyj8APrLMeta } from "/builds/datakode/serpic2/nuxt/pages/tableau-de-bord.vue?macro=true";
import { default as utilisateurs_45permissionsfZYpO7JrPtMeta } from "/builds/datakode/serpic2/nuxt/pages/utilisateurs-permissions.vue?macro=true";
import { default as formulaire5CIBeRlQ8XMeta } from "/builds/datakode/serpic2/nuxt/pages/utilisateurs/[k]/formulaire.vue?macro=true";
import { default as indexYSK0EJfPMKMeta } from "/builds/datakode/serpic2/nuxt/pages/utilisateurs/[k]/index.vue?macro=true";
import { default as _91k_93C7lNjMqgufMeta } from "/builds/datakode/serpic2/nuxt/pages/utilisateurs/[k].vue?macro=true";
import { default as utilisateursujrgOxI09YMeta } from "/builds/datakode/serpic2/nuxt/pages/utilisateurs.vue?macro=true";
export default [
  {
    name: "carte-brulages-iframe",
    path: "/carte-brulages/iframe",
    meta: iframerOnfBYfsZQMeta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/carte-brulages/iframe.vue")
  },
  {
    name: "carte-brulages",
    path: "/carte-brulages",
    component: () => import("/builds/datakode/serpic2/nuxt/pages/carte-brulages/index.vue")
  },
  {
    name: "configuration",
    path: "/configuration",
    meta: configurationYFGWes1JRwMeta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/configuration.vue")
  },
  {
    name: "connexion",
    path: "/connexion",
    meta: indexESEyfMg2onMeta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/connexion/index.vue")
  },
  {
    name: mot_45de_45passe_45oublieb6nHlAuNEhMeta?.name,
    path: "/connexion/mot-de-passe-oublie",
    meta: mot_45de_45passe_45oublieb6nHlAuNEhMeta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/connexion/mot-de-passe-oublie.vue"),
    children: [
  {
    name: "connexion-mot-de-passe-oublie-token",
    path: ":token()",
    meta: _91token_93HO6tyO21ihMeta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue")
  },
  {
    name: "connexion-mot-de-passe-oublie",
    path: "",
    meta: indexveMCc02E0PMeta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue")
  }
]
  },
  {
    name: "declarations",
    path: "/declarations",
    meta: declarationsxsY5LC4FbmMeta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/declarations.vue"),
    children: [
  {
    name: _91k_93a0uhLlgqhqMeta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/serpic2/nuxt/pages/declarations/[k].vue"),
    children: [
  {
    name: "declarations-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/datakode/serpic2/nuxt/pages/declarations/[k]/formulaire.vue")
  },
  {
    name: "declarations-k",
    path: "",
    component: () => import("/builds/datakode/serpic2/nuxt/pages/declarations/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "erreur",
    path: "/erreur",
    meta: erreuriHtTpgwxPLMeta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/erreur.vue")
  },
  {
    name: "historique",
    path: "/historique",
    meta: historique9pRQ84V1oAMeta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/historique.vue")
  },
  {
    name: "homepage",
    path: "/homepage",
    meta: homepagecAVS8RniayMeta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/homepage.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/datakode/serpic2/nuxt/pages/index.vue")
  },
  {
    name: "legal-notices",
    path: "/legal-notices",
    meta: legal_45noticesHnV02jHqU9Meta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/legal-notices.vue")
  },
  {
    name: "organismes",
    path: "/organismes",
    meta: organismes5wjZrvMLOqMeta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/organismes.vue"),
    children: [
  {
    name: _91k_93hpJIL3OmVIMeta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/serpic2/nuxt/pages/organismes/[k].vue"),
    children: [
  {
    name: "organismes-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/datakode/serpic2/nuxt/pages/organismes/[k]/formulaire.vue")
  },
  {
    name: "organismes-k",
    path: "",
    component: () => import("/builds/datakode/serpic2/nuxt/pages/organismes/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: profilrK8cm1yOilMeta?.name,
    path: "/profil",
    meta: profilrK8cm1yOilMeta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/profil.vue"),
    children: [
  {
    name: "profil",
    path: "",
    component: () => import("/builds/datakode/serpic2/nuxt/pages/profil/index.vue")
  },
  {
    name: "profil-organisme",
    path: "organisme",
    component: () => import("/builds/datakode/serpic2/nuxt/pages/profil/organisme.vue")
  }
]
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    meta: tableau_45de_45bord6iZyj8APrLMeta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/tableau-de-bord.vue")
  },
  {
    name: "utilisateurs-permissions",
    path: "/utilisateurs-permissions",
    meta: utilisateurs_45permissionsfZYpO7JrPtMeta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/utilisateurs-permissions.vue")
  },
  {
    name: "utilisateurs",
    path: "/utilisateurs",
    meta: utilisateursujrgOxI09YMeta || {},
    component: () => import("/builds/datakode/serpic2/nuxt/pages/utilisateurs.vue"),
    children: [
  {
    name: _91k_93C7lNjMqgufMeta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/serpic2/nuxt/pages/utilisateurs/[k].vue"),
    children: [
  {
    name: "utilisateurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/datakode/serpic2/nuxt/pages/utilisateurs/[k]/formulaire.vue")
  },
  {
    name: "utilisateurs-k",
    path: "",
    component: () => import("/builds/datakode/serpic2/nuxt/pages/utilisateurs/[k]/index.vue")
  }
]
  }
]
  }
]