import formatDate from '../utils/formatDate.js'
import formatDateTime from '../utils/formatDateTime.js'


export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.provide('formatDate', (date:string, monthDisplay:string) => {
        return formatDate(date, monthDisplay)
    })
    nuxtApp.provide('formatDateTime', (datetime:string, monthDisplay:string) => {
        return formatDateTime(datetime, monthDisplay)
    })
})
