<script lang="ts">
import idPageInit from '~/packages/datakode/nuxt-datakode-utils/utils/idPageInit.vue'

export default {
    name: 'DeclarationPage',
    extends: idPageInit,
}
</script>

<template>
    <ModalRight
        ref="modalParent"
        :saving="saving"
        :default-url="defaultUrl"
        :title-modal="titleModal"
        :is-form="isForm"
        custom-button-color="primary"
        :custom-button-block="true"
        :edit-disabled="!meta?.editable"
        :delete-disabled="!meta?.deletable"
        :permission-name="permissionName"
        :translation-name="$attrs['translation-name']"
        :data="fetchedData"
        save-text="Sauvegarder la déclaration"
        @save="save"
        @close-modal="closeModal"
    >
        <template #content>
            <v-form ref="form" fast-fail @submit.prevent>
                <NuxtPage
                    ref="display" :loading="loading"
                    :data="fetchedData" :rules="rules"
                    :translation-name="translationName"
                    :request-name="requestName"
                    :meta="meta"
                    :is-form="isForm"
                />
            </v-form>
        </template>
    </ModalRight>
</template>
