

<template>
  <v-alert
    v-if="showAlertMessage && !view_alert"
    :type="colorAlertMessage"
    variant="tonal"
    class="my-3 d-print-none"
    :closable="closableAlertMessage"
    @click:close="view_alert = true"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="contentAlertMessage" />
  </v-alert>
</template>

<script lang="ts">
import {useConfigStore} from "~/store/index";
import MD5 from "crypto-js/md5";

export default {
    name : "GlobalAlert",
    setup() {
        const content = useConfigStore().currentConfig.alert_message_content
        let view_alert = false
        if (content) {
            const md5Hash = MD5(content).toString()
            view_alert = useCookie(`view_alert_${md5Hash}`, { maxAge : 2629800})
        }
        return {
            view_alert
        }
    },
    data: () => ({
        showAlertMessage : useConfigStore().currentConfig.alert_message_show,

        contentAlertMessage : useConfigStore().currentConfig.alert_message_content,
        closableAlertMessage : useConfigStore().currentConfig.alert_message_closable,
        colorAlertMessage : useConfigStore().currentConfig.alert_message_color,
    }),
}
</script>
