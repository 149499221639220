<script lang="ts">
import idPageInit from '~/packages/datakode/nuxt-datakode-utils/utils/idPageInit.vue'
import { useUserStore } from '~/store/index'

export default {
    extends: idPageInit,
    setup() {
        useHead({
            title: 'Serpic | Profil',
            meta: [
                { name: 'description', content: 'Gestion du profil' },
            ],
        })
        definePageMeta({
            middleware: ['auth'],
        })
        const userStore = useUserStore()
        return {
            userStore,
        }
    },
    data() {
        return {
        }
    },
    computed: {
        currentRoute() {
            return this.$route.path
        },
    },
    watch: {
        currentRoute() {
            if (!this.loading) {
                this.requestNameOverload = this.$route.path === '/profil/organisme' ? `organizations` : 'my-profile'
                this.currentKeyOverload = this.$route.path === '/profil/organisme' ? this.$currentUser()?.organization.k : ''
                this.translationNameOverload = this.$route.path === '/profil/organisme' ? 'organismes' : 'utilisateurs'

                this.fetchedData = null
                this.getFullData()
            }
        },
    },
    mounted() {
        this.requestNameOverload = this.$route.path === '/profil/organisme' ? `organizations` : 'my-profile'
        this.currentKeyOverload = this.$route.path === '/profil/organisme' ? this.$currentUser()?.organization.k : ''
        this.translationNameOverload = this.$route.path === '/profil/organisme' ? 'organismes' : 'utilisateurs'
        this.getFullData()
    },
}
</script>

<template>
    <div>
        <NuxtPage
            ref="display" :loading="loading"
            :data="fetchedData" :rules="rules"
            :translation-name="$route.path === '/profil/organisme' ? 'organismes' : 'utilisateurs'"
            :saving="saving"
            @save="save(); userStore.fetchUser();"
        />
    </div>
</template>
