<template>
  <!-- https://vuetifyjs.com/en/components/navigation-drawers/ -->
  <v-navigation-drawer 
    v-model="drawer" 
    :rail="$vuetify.display.mobile ? false : rail"
    expand-on-hover
    :permanent="!$vuetify.display.mobile"
    :temporary="$vuetify.display.mobile"
  >
    <slot
      name="menu"
      :rail="rail"
    /> 
  </v-navigation-drawer>
</template>

<script lang="ts">
export default {
    name : "NavigationDrawer",
    data: () => ({
        drawer : true,
        rail: false,
    }),
    methods : {
        showNavigation () {
            if (this.$vuetify.display.mobile) {
                this.drawer = !this.drawer // SHOW OR HIDE MOBILE MENU
            } else {
                // this.drawer = true
                this.rail = !this.rail // EXPAND OR RETRACT DESKTOP MENU
            }
        }
    },
}
</script>
