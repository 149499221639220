import { defineStore } from 'pinia'

export const baseUseNotifyStore = defineStore( 'notify', {
    state: () => {
        return {
            lastId : 1,
            toasters : [],
        }
    },
    actions: {
        showMessage (toast: object) {
            const newToast = {...toast, id : this.lastId}
            this.toasters.push(newToast)
            this.lastId = this.lastId + 1

            if (newToast.timer > 0 || !Number.isInteger(newToast.timer)) { // if timer is not defined : 5000 by default
                setTimeout(() => {
                    this.removeToaster(newToast.id)
                }, newToast.timer || 5000);
            }

        },
        removeToaster (index) {
            this.toasters = this.toasters.filter( t => t.id !== index)
        }
    }
})
