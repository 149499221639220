<template>
  <div
    v-if="loading"
    id="loader-overlay"
  >
    <v-row
      class="h-75 mx-2"
      justify="center"
      align="center"
    >
      <v-col class="text-center pa-0">
        <div class="profile-main-loader">
          <div class="loader">
            <div class="circular-loader">
              <slot name="loader" />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row
      class="h-25 mx-2"
      justify="center"
      align="center"
    >
      <v-col class="text-center">
        <slot name="logo" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    loading: {type: Boolean},
  }
}
</script>
