import colors from "@/assets/colors.json"

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.provide('getColor', (colorKey:string) => {
        const keys = colorKey.split('.')
        let color = colors
        try {
            keys.forEach(k => color = color[k])
            return typeof color === 'string' ? color : '#666666'
        } catch {
            return '#666666'
        }
        
    })
})
