export default (dateString, monthDisplay = 'short') => {
    let date = dateString;
    if (!date) {
        return null
    }
    if (typeof dateString === 'string') {
        date = new Date(dateString);
    }
    return date.toLocaleString('fr',{
        year: "numeric",
        month: monthDisplay,
        day: "numeric",
        hour: '2-digit', 
        minute: '2-digit'
    })
}