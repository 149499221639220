<script lang="ts">
import { useRoute } from 'vue-router'
import { useConfigStore } from '~/store/index'

export default {
    name: 'DefaultLayout',
    setup() {
        const route = useRoute()
        return {
            route,
        }
    },
    data: () => ({
    }),
    computed: {
        currentPage() {
            // return this.$currentPath(this.$route.matched[0].path).pop()
            return this.$router ? this.$router.currentRoute : this.$currentPath(this.$route.matched[0].path).pop()
        },
        loading() {
            return useConfigStore()?.globalLoading
        },
        maintenance() {
            return useConfigStore()?.maintenance
        },

    },

    mounted() {
        useConfigStore().setGlobalLoading(false)
    },
    methods: {
        cleanedRouteName(name) {
            const nameUpdate = name.replace(/[^a-z ]/gi, '')
            return nameUpdate === 'index' ? '' : nameUpdate
        },
        showTitle(path) {
            path = path.replaceAll('/', '.')
            return path
        },
    },
}
</script>

<template>
    <div>
        <header>
            <v-layout>
                <MenuGlobalLoader :loading="loading">
                    <template #loader>
                        <img class="mx-auto" src="@/assets/images/logo-alone.png" alt="Serpic logo">
                    </template>
                    <template #logo>
                        <div class="text-uppercase text-overline" style="font-size: 16px!important">
                            SERPIC
                        </div>
                    </template>
                </MenuGlobalLoader>

                <MenuMaintenanceMessage>
                    <template #logo>
                        <img class="mx-auto" src="@/assets/images/logo.png" alt="Serpic logo">
                    </template>
                </MenuMaintenanceMessage>

                <client-only>
                    <MenuNavigationDrawer v-if="!maintenance" ref="MenuNavigationDrawer">
                        <template #menu="{ rail }">
                            <MenuNavigationSidebar ref="navigationSidebar" :rail="rail" />
                        </template>
                    </MenuNavigationDrawer>

                    <v-main v-if="!maintenance" id="main-content" class="bg-custom-light">
                        <MenuToolbar ref="Toolbar" @show-navigation="$refs.MenuNavigationDrawer.showNavigation()">
                            <template #title>
                                {{ $translate($config.public.departement_number) }}
                                <!-- <img v-if="$config.public.departement_number" class="mx-auto" :src="`@/assets/images/${$config.public.departement_number}.png`" alt="Département logo"> -->
                            </template>
                            <template #rightMenu>
                                <MenuUserProfile />
                            </template>
                        </MenuToolbar>

                        <div id="page-content" class="pa-6">
                            <div>
                                <h1 class="text-h4 mb-5">
                                    {{ $translate(cleanedRouteName(route.name)) }}
                                </h1>
                                <MenuGlobalAlert />
                            </div>
                            <slot /> <!-- OUTPUT PAGE -->
                            <Toaster ref="toaster" />
                        </div>
                        <MenuFooter />
                    </v-main>
                </client-only>
            </v-layout>
        </header>
    </div>
</template>
