import {defineStore} from 'pinia'
import {useCookie, useRuntimeConfig} from "nuxt/app";

export const baseUseUserStore = defineStore( 'users', {

    state: () => {
        return {
            currentUser: {},
            access_token: "",
            loading: true,
        }
    },
    actions: {
        async fetchUser(access_token_send:object): Promise<Object> {
            // const {$api} = useNuxtApp();

            this.loading = true
            const access_token: CookieRef<string> = access_token_send ? access_token_send : useCookie<string>('access_token')
            const config: RuntimeConfig = useRuntimeConfig()

            const fail = function (elt: baseUseUserStore): boolean {
                elt.loading = false
                return false
            }
            try {
                if (!access_token.value) {
                    const res = await $fetch(`${config.public.apiBase}/guest-user`, {
                        method: 'GET',
                    })
                    if (!res) {
                        return fail(this);
                    }
                    this.loading = false
                    this.currentUser = res.data
                    return res
                } else {
                    const res = await $fetch(`${config.public.apiBase}/user`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${access_token.value}`,
                            Accept: 'application/json',
                        },
                    })
                    if (!res) {
                        return fail(this);
                    }
                    this.loading = false
                    this.currentUser = res.data
                    this.access_token = access_token.value
                    return res
               
                }
            } catch {
                fail(this)
            }
        },
        setUser(value: object): void {
            this.currentUser = value
        },
        async clearUser() {
            const config: RuntimeConfig = useRuntimeConfig()

            this.currentUser = {}
            this.access_token = ""
            const res = await $fetch(`${config.public.apiBase}/guest-user`, {
                method: 'GET',
            })
            if (res) {
                this.currentUser = res.data
            }
            this.loading = false
        }
    },
    getters: {
        user: state => state.currentUser,
    },
})
