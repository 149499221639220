import { useCookie } from 'nuxt/app'
import { ApiService } from '../utils/api.service'
import { useNotifyStore, useUserStore } from '~/store'

export default defineNuxtPlugin(nuxtApp => {
    const access_token = useCookie<string>('access_token')
    const notifyStore = useNotifyStore()
    const userStore = useUserStore()
    const { $i18n } = useNuxtApp();

    const apiService = new ApiService($fetch, nuxtApp.$config, access_token, notifyStore, $i18n, userStore)
    nuxtApp.provide('api', apiService)
})
