
export default defineNuxtPlugin( () => {
    return {
        provide: {
            currentPath: (path : object) => {
                const pathArray = path.split("/")
                pathArray.shift()

                const breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {

                    const initTitle = pathArray.slice(0, idx +1).join('.')

                    breadcrumbArray.push({
                        path: path,
                        to : "/" + pathArray.slice(0, idx +1).join('/'),
                        title: initTitle.replace(/-/g,'')
                    });

                    breadcrumbArray = breadcrumbArray.filter( item => item.title !== '') // REMOVE HOMEPAGE
                    breadcrumbArray = breadcrumbArray.filter( item => item.path !== 'gestion')

                    return breadcrumbArray;
                }, [])

                return breadcrumbs;
            }
        }
    }
})