import { useUserStore } from '~/store'

export default defineNuxtRouteMiddleware(async () => { // (to, from)
    const userStore = useUserStore()
    const currentUser = userStore.currentUser

    /* IF NO USER STORED LOCALLY : GET USER OR REDIRECT TO CONNEXION PAGE */
    if (currentUser && currentUser.k && !currentUser.organization) {
        // ONLY IF NOT A DECLARANT & NOT AN ADMIN
        if (currentUser.role !== 'admin' && currentUser.role !== 'declarant') {
            return navigateTo('/erreur')
        }
    }
})
