export default defineNuxtPlugin(() => {
  const { $i18n } = useNuxtApp();

  return {
    provide: {
      translate: (msg: string, data: object, fallback: string | null = 'index') => {

        const debug: boolean = false;
        const recursiveTranslate = function (msg: string, data: object): string | null {
          if (debug) {
            console.log('translate: ' + msg);
          }
          const tradMsg: string = $i18n.t(`${msg}`, data)
          if (tradMsg !== msg) {
            if (debug) {
              console.log('    FOUND');
            }
            return tradMsg
          }
          if (fallback) {
            if (msg.endsWith('.' + fallback)) {
              const split = `${msg}`.split('.');
              split.pop(); // remove .index
              split.shift(); // remove first element
              if (split.length === 0) {
                return null;
              }
              return recursiveTranslate(split.join('.'), data)
            }
            return recursiveTranslate(`${msg}.index`, data)
          } else {
            const split = `${msg}`.split('.');
            split.shift(); // remove first element
            if (split.length === 0) {
              return null;
            }
            return recursiveTranslate(split.join('.'), data)
          }
        }
        return recursiveTranslate(msg, data) ?? msg;

      }
    }
  }

})
