<script lang="ts">
export default {
}
</script>

<template>
    <v-footer color="primary" style="z-index:50; position: inherit;">
        <div class="d-flex align-center">
            <div />
            <v-spacer />
            <div class="px-2">
                © {{ new Date().getFullYear() }}
            </div>
        </div>
    </v-footer>
</template>
