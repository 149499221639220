import {defineStore} from 'pinia'
import {useRuntimeConfig} from "#app";

export const baseUseConfigStore = defineStore('config', {

    state: () => {
        return {
            currentConfig: {},
            loading: true,
            globalLoading : true,
            maintenance : false,
        }
    },
    actions: {
        setGlobalLoading (val:boolean) {
            this.globalLoading = val
        },
        async fetchConfig(): Promise<boolean> {
            this.loading = true
            const config: RuntimeConfig = useRuntimeConfig()

            const fail = function (elt: useConfigStore, e: unknown): boolean {
                if (e?.response?.status === 503) {
                    elt.maintenance = true
                }
                elt.loading = false
                return false
            }

            try {
                const res = await $fetch(`${config.public.apiBase}/config`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                    },
                })
                if (!res) {
                    return fail(this);
                }
                this.loading = false
                this.currentConfig = res.data
                return true
            } catch (e) {
                fail(this, e)
            }

        }
    },
    getters: {
        config: state => state.currentConfig,
        getValue: (state) => {
            return (configKey: string) => state.currentConfig[configKey];
        },
        setValue: (state) => {
            return (configKey: string, value) => {
                state.currentConfig[configKey] = value;
                return value;
            }
        }
    },
})
