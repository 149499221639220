import lang_fr from './lang/fr'
import permissions from './lang/permissions'
import library_fr from './packages/datakode/nuxt-datakode-utils/lang/fr'

export default defineI18nConfig(() => ({
    legacy: false,
    missingWarn: false,
    fallbackWarn: false,
    silentTranslationWarn: true,
    warnHtmlMessage: false,
    locale: 'fr',
    runtimeOnly: false,
    messages: {
        fr: {
            ...lang_fr,
            ...permissions,
            ...library_fr,
        },
    },
}))
