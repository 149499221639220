<script lang="ts">
import tablePageInit from '~/packages/datakode/nuxt-datakode-utils/utils/tablePageInit.vue'

export default {
    name: 'ListUsers',
    extends: tablePageInit,
    setup() {
        useHead({
            title: 'Utilisateurs',
            meta: [
                { name: 'description', content: 'Gestion des utilisateurs' },
            ],
        })
        definePageMeta({
            middleware: ['auth'],
        })
    },
    data() {
        return {
            permissionName: 'user',
            requestName: 'users',
            defaultUrl: '/utilisateurs',
            translationName: 'utilisateurs',
            valueAsViewLink: [''],
            columns: [
                { dataKey: 'name' },
                { dataKey: 'email' },
                { dataKey: 'organization.name', template: [this.isChips] },
                { dataKey: 'role', transform: [this.toTranslate], template: [this.isChips] },
                { dataKey: 'active', template: [this.showActive] },
                { dataKey: 'last_login', transform: [this.isDateTime] },
            ],
            initSort: {
                // key : "name",
                // order : "asc"
            },
            filtersOverload: ['name', 'email', 'organization_id', 'role', 'active', 'where_is_new_user'],
        }
    },
    methods: {
        showCLEdate(key, value, template, item) {
            let html = template
            if (item.cle) {
                html += ` <br/>
                        <v-chip size="x-small" class="mb-1">
                            Date de CLE : ${this.$formatDate(item.cle.date_cle)}
                        </v-chip>
                    `
            }
            return html
        },
        showActive(key, value, template, item) {
            let html = ''
            html += `
                    <v-chip size="x-small" color="${value ? 'green' : 'error'}">
                        ${value ? 'Activé' : 'Désactivé'}
                    </v-chip>
                    `
            if (!item.validated_at) {
                html += `
                        <v-chip color="info" size="x-small" class="mb-1">
                            Nouvel utilisateur
                        </v-chip>
                    `
            }
            return html
        },
        resetPassword(item) {
            this.$api.setRequest({
                url: 'reset_password',
                method: 'POST',
                body: {
                    email: item.email,
                },
            }).then(() => {
                this.$showToaster({ title: 'Réinitialisation du mot de passe', text: `L'utiliateur ${item.name} a reçu un mail de réinitialisation.`, color: 'success', icon: 'mdi-check-circle' })
            })
        },
    },
}
</script>

<template>
    <div id="organismes">
        <ListActionsButtons
            :query="$refs?.filters?.query"
            :request-name="requestName"
            :translation-name="translationName"
            :exportable="exportable"
            :default-url="defaultUrl"
            :permission-name="permissionName"
        />
        <ListFilters
            v-if="filters"
            ref="filters"
            :permission-name="permissionName"
            :filters="filters"
            :translation-name="translationName"
            :filters-overload="filtersOverload"
            @load-data="loadData()"
            @reset-pagination="$refs.pagination.resetPagination()"
        />
        <v-card class="pa-5">
            <v-btn
                color="secondary" variant="tonal"
                class="mb-4" @click.prevent="$router.push('/utilisateurs-permissions')"
            >
                Voir le tableau des permissions
            </v-btn>
            <!-- <ListUtilisateursPermissionsTable /> -->

            <ListTable
                ref="table"
                :permission-skip="true"
                :value-as-view-link="valueAsViewLink"
                :permission-name="permissionName"
                :translation-name="translationName"
                :columns="columns"
                :sortable="sortable"
                :default-url="defaultUrl"
                :loading="loading"
                :data="data"
                :init-sort="initSort"
                @reset-pagination="$refs.pagination.resetPagination()"
                @load-data="loadData()"
                @delete-data="deleteData($event)"
            >
                <template #optionsList="{ item }">
                    <!--  class="link-as-list-item" -->
                    <v-list-item v-if="!item.deleted && $permissionCheck(`${permissionName}_reset_password`)" density="compact" @click="resetPassword(item)">
                        <v-list-item-title class="text-caption">
                            <v-icon icon="mdi-lock-reset" size="small" class="mr-2" color="dark" />
                            {{ $translate(`${translationName}.reset_password`) }}
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </ListTable>
            <div>
                <ListPagination ref="pagination" :total="total" :loading="loading" :last-page="lastPage" @load-data="loadData" />
            </div>
        </v-card>

        <NuxtPage
            :request-name="requestName" :default-url="defaultUrl"
            :translation-name="translationName" :permission-name="permissionName"
            @load-data="loadData()"
            @delete-data="deleteData($event)"
        />
    </div>
</template>
