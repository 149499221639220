<script lang="ts">
import { useUserStore } from '~/store'

export default {
    name: 'MenuUserProfile',
    setup() {
        const access_token = useCookie<string>('access_token')
        const config = useRuntimeConfig()
        const userStore = useUserStore()

        return {
            access_token,
            config,
            userStore,
        }
    },
    data: () => ({
        menu: false,
        message: false,
    }),
    mounted() {
    },
    methods: {
        save() {
            this.menu = false
            // this.$showToaster({ text : `Le profil a été sauvegardé`, color : "success", icon : "mdi-check-circle" })
        },
        async logout() {
            await this.$api.setRequest({
                url: 'logout',
                method: 'POST',
            })
            this.access_token = null
            this.userStore.clearUser()
            this.$router.push('/')
        },
    },
}
</script>

<template>
    <div>
        <client-only>
            <div v-if="$currentUser()?.k">
                <v-menu v-model="menu" :close-on-content-click="false" location="end">
                    <template #activator="{ props }">
                        <v-btn v-bind="props" class="mr-2">
                            {{ $currentUser().name }}
                        </v-btn>
                    </template>

                    <v-card min-width="300">
                        <v-list>
                            <v-list-item :title="$currentUser().name">
                                <v-chip size="small" :color="$getColor(`utilisateurs.role.${$currentUser().role}`)">
                                    {{ $translate(`utilisateurs.role.${$currentUser().role}`) }}
                                </v-chip>
                                <v-divider class="my-3" />
                                <p class="text-caption mt-1">
                                    <v-icon size="x-small" class="mr-3">
                                        mdi-email-outline
                                    </v-icon>
                                    {{ $currentUser().email }}
                                </p>
                                <p class="text-caption mt-1">
                                    <v-icon size="x-small" class="mr-3">
                                        mdi-city-switch
                                    </v-icon>
                                    {{ $currentUser()?.organization?.name || "Aucun organisme" }}
                                </p>

                                <div v-if="$permissionCheck('profile_show')">
                                    <NuxtLink to="/profil" class="text-decoration-none" @click="menu = false">
                                        <v-btn color="primary" block class="mt-5">
                                            Mon profil
                                        </v-btn>
                                    </NuxtLink>
                                </div>
                                <div v-if="$permissionCheck('organization_edit_user') && $currentUser()?.organization">
                                    <NuxtLink to="/profil/organisme" class="text-decoration-none" @click="menu = false">
                                        <v-btn color="secondary" block class="mt-2">
                                            Mon organisme
                                        </v-btn>
                                    </NuxtLink>
                                </div>
                                <v-btn color="danger" class="mt-2" block @click="logout">
                                    Se déconnecter
                                </v-btn>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </div>
            <div v-else class="mx-5">
                <v-btn block variant="elevated" color="primary" @click="$router.push('/connexion')">
                    Connexion
                </v-btn>
            </div>
        </client-only>
    </div>
</template>
