export default {
    'configuration': 'Paramètres',
    'connexion': '',
    'cities': 'Communes',
    'cadastre': 'Cadastre',
    'limites_administratives_express': 'Limites administratives',
    'massif_forestier': 'Carte forestière v2',
    'massif_forestier_public': 'Forêts publiques',
    'zsm_gbarbu': 'ZSM Gypaète barbu',
    'zsm_vp': 'ZSM Vautour percnoptère',

    // 09 LAYERS
    'n_enp_apb2_s_r76': 'Arrêté de Protection de Biotope',
    'r_do_s_r76': 'Natura 2000 ZPS',
    'Reserves_naturelles_regionales': 'Réserve Naturelle Régionale',
    'r_rncfs_s_r76': 'Réserve naturelle de chasse et de faune sauvage',
    'Reserves_biologiques': 'Réserve Biologique ONF',
    'N2000_ZSC': 'Sites Natura 2000 au titre de la Directive Habitats',
    // ------

    '31': 'Haute-Garonne',
    '09': 'Ariège',
    '64': 'Pyrénées-Atlantiques',
    '65': 'Hautes-Pyrénées',
    'ressources': {
        index: 'Ressources',
    },
    'homepage_html': {
        toaster: {
            PUT: {
                success: {
                    title: 'Page d\'accueil',
                    text: 'La page d\'accueil a été mise à jour',
                },
            },
        },
    },
    'tableaudebord': {
        index: 'Tableau de bord',
    },
    'cartebrulages': 'Carte des brûlages',
    'profil': 'Mon profil',
    'profilorganisme': 'Mon organisme',
    'utilisateurskformulaire': 'Utilisateurs',
    'utilisateursk': 'Utilisateurs',
    'utilisateurs': {
        toaster: {
            POST: {
                success: {
                    title: 'Compte utilisateur',
                    text: 'Les informations {name} ont été enregistrées',
                },
            },
            PUT: {
                success: {
                    title: 'Compte utilisateur',
                    text: 'Les informations {name} ont été enregistrées',
                },
            },
            DELETE: {
                success: {
                    title: 'Compte utilisateur',
                    text: 'Le compte utilisateur a été supprimé',
                },
            },
        },
        index: 'Utilisateurs',
        add: 'Ajouter un utilisateur',
        export: 'Exporter les utilisateurs',
        view: 'Fiche utilisateur',
        edit: 'Fiche utilisateur',
        reset_password: 'Réinitialiser mot de passe',
        credentials: {
            created_at: 'Compte crée le',
        },
        validated_at: 'Compte validé le',
        reason: 'Motif lors de l\'inscription',
        delete: {
            index: 'Supprimer',
            title: 'Supprimer l\'utilisateur',
            text: 'L\'utilisateur <b>{name}</b> va être définitivement supprimé. <br/> Souhaitez-vous continuer ?',
        },
        name: 'Nom',
        email: 'E-mail',
        last_login: 'Dernière connexion',
        contact: {
            name: 'Nom',
            email: 'E-mail',
            address: 'Adresse',
            postcode: 'Code postal',
            city: 'Commune',
            phone: 'Téléphone',
            mobile: 'Mobile',
        },
        active: {
            index: 'Utilisateur activé',
            true: 'Seulement les actifs',
            false: 'Seulement les désactivés',
            all: 'Tous',
        },
        organization: {
            index: 'Nom de l\'organisme',
            v: 'Nom de l\'organisme',
            name: 'Nom de l\'organisme',
        },
        organization_id: 'Organisme',
        role: {
            index: 'Rôle',
            guest: 'Invité',
            declarant: 'Déclarant',
            admin: 'Administrateur',
            mairie: 'Maire',
            admin_cle: 'Administrateur CLE',
            animateur_cle: 'Animateur CLE',
            cle: 'Participant CLE',
            ddt: 'DDT',
            sdis: 'SDIS',
            onf: 'ONF',
            cdbd: 'CDBD',
            consultation: 'Consultation',
            consultation_manage_users: 'Consultation & Gestion des utilisateurs',
        },
        password: 'Mot de passe',
        passwordConfirm: 'Confirmation du mot de passe',
        group_notifications: 'Recevoir une seule notification e-mail journalièrement',
        receive_notifications: 'Recevoir des notifications par e-mail',
        where_is_new_user: {
            index: 'Nouvel utilisateur',
            new_user: 'Seulement les nouveaux utilisateurs',
            old_user: 'Seulement les anciens utilisateurs',
        },
    },
    'organismeskformulaire': '',
    'organismesk': '',
    'organismes': {
        toaster: {
            POST: {
                success: {
                    title: 'Organismes',
                    text: 'L\'organisme {name} a été enregistré',
                },
            },
            PUT: {
                success: {
                    title: 'Organismes',
                    text: 'L\'organisme {name} a été enregistré',
                },
            },
            DELETE: {
                success: {
                    title: 'Organismes',
                    text: 'L\'organisme a été supprimé',
                },
            },
        },
        index: 'Organismes',
        add: 'Ajouter un organisme',
        export: 'Exporter les organismes',
        view: 'Voir l\'organisme : <small>{name}</small>',
        edit: 'Modifier l\'organisme : <small>{name}</small>',
        delete: {
            index: 'Supprimer l\'organisme',
            title: 'Supprimer l\'organisme',
            text: 'Vous allez supprimer définitivement l\'organisme {name}.<br><ul><li>Si des informations de ce type d\'organisme doivent apparaitrent sur le PDF des déclarations, elles n\'apparaitront plus.</li><li> Si le type est une CLE, les communes associées ne passeront plus en CLE. </li></ul><br/> Souhaitez-vous continuer ?',
        },
        name: 'Nom de l\'organisme',
        type: {
            index: 'Type',
            dept: 'Département',
            mairie: 'Mairie',
            cle: 'CLE',
            onf: 'ONF',
            ddt: 'DDT',
            sdis: 'SDIS',
            cdbd: 'CDBD',
        },
        users_count: 'Utilisateurs',
        communes_count: 'Communes',
        commune_name: 'Communes',
        communes_complete: 'Communes',
        commune_id: 'Commune',
        communes: {
            id: 'Nom de la commune',
        },
        code: 'Code',
        phone: 'Téléphone',
        onf_phone: 'Téléphone ONF',
        ref_firstname: 'Prénom référent',
        ref_lastname: 'Nom référent',
        mayor: 'Nom du maire',
        police_phone: 'Téléphone gendarmerie',
        cle: {
            date_cle: 'Date de la CLE',
        },
    },
    'declarationskformulaire': 'Déclarations',
    'declarationsk': 'Déclarations',
    'declarations': {
        toaster: {
            POST: {
                success: {
                    title: 'Déclarations',
                    text: 'Les informations {name} ont été enregistrées',
                },
            },
            PUT: {
                success: {
                    title: 'Déclarations',
                    text: 'Les informations {name} ont été enregistrées',
                },
            },
            DELETE: {
                success: {
                    title: 'Déclarations',
                    text: 'La déclaration a été supprimée',
                },
            },
        },
        created_by_me: {
            index: 'Mes déclarations',
            true: 'Voir mes déclarations',
            false: 'Voir toutes les déclarations',
        },
        index: 'Déclarations',
        add: 'Ajouter une déclaration',
        export: 'Exporter les déclarations',
        view: 'Voir la déclaration {k}',
        pdf: 'Télécharger le PDF',
        edit: 'Modifier la déclaration {k}',
        delete: {
            index: 'Supprimer la déclaration',
            title: 'Supprimer la déclaration',
            text: 'Vous allez supprimer définitivement la déclaration {k}. Souhaitez-vous continuer ?',

        },
        copy: {
            index: 'Copier les déclarations',
            withNotices: 'Avec les avis',
            withoutNotices: 'Sans les avis',
        },
        crossing: {
            index: 'Croisements',
            forest: 'Forêt',
            zsm: 'ZSM',
            clean: 'Aucun',
        },
        crossing_forest: 'Croisement Forêt',
        crossing_zsm: 'Croisement ZSM',
        name: 'Nom de la déclaration',
        id: 'Numéro',
        called_place: 'Lieu-dit',
        start_date: 'Date de début de réalisation',
        end_date: 'Date de fin de réalisation',
        status: {
            index: 'Statut',
            none: 'En cours de calcul',
            created: 'Déposée',
            in_progress: 'En cours d\'instruction',
            valid: 'Validée',
            invalid: 'Refusée',
        },
        season_id: 'Saison',
        seasons: {
            id: 'Saison',
        },
        season: {
            index: 'Saison',
            name: 'Saison',
        },
        declarants: {
            name: 'Prénom et nom du déclarant',
        },
        declarant_id: 'Déclarant',
        declarant: {
            index: 'Prénom et nom du déclarant',
            name: 'Prénom et nom du déclarant',
            address: 'Adresse du déclarant',
            postcode: 'Code postal du déclarant',
            city: 'Commune du déclarant',
            phone: 'Téléphone du déclarant',
            mobile: 'Mobile du déclarant',
            email: 'E-mail du déclarant',
        },
        commune_id: 'Commune',
        communes: {
            id: 'Commune',
        },
        commune: {
            index: 'Commune',
            name: 'Commune',
        },
        geometry: 'Géométrie',
        declaration_type_id: 'Type de déclaration',
        declaration_types: {
            index: '',
            id: 'Type de déclaration',
            name: 'Nom',
            geometry_type: 'Type de géométrie',
            cle: 'CLE',
            start: 'Date de début',
            end: 'Date de fin',
            warning: 'Message d\'alerte',
            engagement: 'Message de validation du formulaire',
        },
        cles: {
            id: 'Clé',
        },
        passing_cle: {
            index: 'Passage en CLE',
            true: 'Oui',
            false: 'Non',
        },
        revision: 'Révision',
        current_step: 'Étape en cours',
        commitment: 'Engagement accepté',
        project_manager_id: 'Maître d\'oeuvre',
        project_manager: {
            index: 'Nom prénom du maître d\'oeuvre',
            name: 'Nom prénom du maître d\'oeuvre',
            phone: 'Téléphone du maître d\'oeuvre (1)',
            mobile: 'Téléphone du maître d\'oeuvre (2)',
        },
        bd: {
            index: 'Brûlage dirigé',
            true: 'Oui',
            false: 'Non',
        },
        last_burn_year: 'Année du dernier brûlage effectif connu de cette zone',
        cdbd_decision: {
            index: 'Avis CDBD',
            null: 'Pas d\'avis',
            true: 'Favorable',
            false: 'Défavorable',
        },
        cdbd_opinion: 'Avis détaillé CDBD',
        cdbd_stakes: {
            index: 'Type d\'enjeux CDBD',
            risques: 'Risques',
            none: 'Aucun',
            securite: 'Sécurité',
            agricoles: 'Agricoles',
            forestiers: 'Forestiers',
            environnementaux: 'Environnementaux',
            surface: 'Surface',
        },
        mayor_decision: {
            index: 'Décision du maire',
            null: 'Pas de décision',
            true: 'Validé',
            false: 'Refusé',
        },
        mayor_opinion: 'Avis détaillé du maire',
        mayor_validation_date: 'Date de décision du maire',
        mayor_conform: 'Prise en compte des autres avis (CLE, ONF ...)',
        mayor_call: 'Si le déclarant doit appeler la mairie avant le brûlage',
        ddt_decision: {
            index: 'Avis DDT',
            null: 'Pas d\'avis',
            true: 'Favorable',
            false: 'Défavorable',
        },
        ddt_opinion: 'Avis détaillé DDT',
        cle_preconisation: 'Préconisation CLE',
        cle_last_year_preconisation: 'Préconisation CLE de l\'année précédente',
        cle_decision: {
            index: 'Avis CLE',
            null: 'Pas d\'avis',
            true: 'Favorable',
            false: 'Défavorable',
        },
        onf_preconisation: 'Préconisation ONF',
        onf_call: 'Si le déclarant doit téléphoner à l\'ONF le jour du brûlage',
        onf_required: 'Avis ONF obligatoire',
        owner_id: 'Propriétaire',
        owner: {
            name: 'Nom prénom du propriétaire',
        },
        owner_type: {
            index: 'Type de propriétaire',
            physique: 'Physique',
            morale: 'Moral',
        },
        declaration_type: {
            index: 'Type de déclaration',
            name: 'Type de déclaration',
        },
        declarant_type: {
            index: 'Type de déclarant',
            owner: 'Propriétaire',
            ind: 'Individuel',
            other: 'Autre',
            paf: 'Président d\'association foncière',
            pgp: 'Président de groupement pastoral',
            water_union: 'Syndicat des rivieres',
            have_right: 'Ayant droit',
            elu: 'Elu',
            etaf: 'Entreprise de travaux agricoles ou forestiers',
        },
        beneficiary_type: {
            index: 'Type d\'ayant droit',
            farmer: 'Fermier',
            tenant: 'Locataire',
            agent: 'Mandataire',
            heir: 'Héritier',
            forced_heir: 'Réservaire',
            purchaser: 'Adjudicataire de coupe',
            other: 'Autre',
        },
        cadastre_section: 'Section cadastrale',
        cadastre_parcel: 'Parcelle cadastrale',
        linear: 'Linéaire',
        heap_count: 'Nombre de tas',
        surface: 'Surface',
        demande_appui: {
            index: 'Demande d\'appui',
            none: 'Aucune',
            cle: 'Commission locale d\'écobuage ',
            cdbd: `Cellule départementale de "brûlages dirigés"`,
        },
        more_burns_planned: 'D\'autres brûlages sont prévus pour cette déclaration',
        burn_status: {
            index: 'Statut de brûlage',
            none: 'Aucun brûlage',
            planned: 'Brûlage prévu',
            ongoing: 'Brûlage potentiellement en cours',
            partial: 'Brûlage partiellement réalisé',
            complete: 'Brûlage réalisé',
        },
        burn_type: {
            index: 'Type d\'écobuage',
            full: 'En plein',
            task: 'Par tâche',
            foot: 'Pied à pied',
        },
        participants: 'Nombre de participants',
        description: 'Description',
        derogation: {
            index: 'Dérogation',
            true: 'Oui',
            false: 'Non',
        },
        vegetation_type: {
            index: 'Type de végétation',
            broussaille: 'Broussaille (ronce, fougère...)',
            jeune_boisement: 'Jeune boisement',
            boisement_adulte: 'Boisement adulte',
            vegetation_arbustive: 'Végétation arbustive',
            arbre_isole: 'Arbre isolé',
        },
        number: 'Numéro chantier',
        where_checked_by_org_type: {
            index: 'Traitées par',
            dept: 'Département',
            mairie: 'Mairie',
            cle: 'CLE',
            onf: 'ONF',
            ddt: 'DDT',
            sdis: 'SDIS',
            cdbd: 'CDBD',
        },
        where_checked_value: {
            index: 'Avis',
            null: 'Non traitée',
            true: 'Favorable',
            false: 'Défavorable',
        },
        where_treatment_by: {
            index: 'À traiter par',
            dept: 'Département',
            mairie: 'Mairie',
            cle: 'CLE',
            onf: 'ONF',
            ddt: 'DDT',
            sdis: 'SDIS',
            cdbd: 'CDBD',
        },
        where_treatment_required: {
            index: 'Type d\'intervention',
            null: 'Tous',
            true: 'Obligatoire',
            false: 'Facultatif',
        },
        where_cle: {
            index: 'Clé',
        },
        brulages: {
            index: 'Brûlage',
            date: 'Date de brûlage',
            surface: 'Surface brûlée',
            description: 'Commentaire sur le brûlage',
        },
        date: 'Date',
        declaration_id: 'N° Déclaration',

    },
    'export': {
        declaration_64: 'Fiches PDF des déclarations',
        declaration_65: 'Fiches PDF des déclarations',
        declaration_31: 'Fiches PDF des déclarations',
        declaration_09: 'Fiches PDF des déclarations',
        declaration_mayor_list: 'Tableau des demandes d\'écobuage mairie',
    },
    'historique': {
        index: 'Historique des déclarations',
        declaration_id: 'N° Déclaration',
        revision: 'Révision',
        declaration_revision: 'Révision',
        event_type: {
            index: 'Action',
            created: 'Création',
            updated: 'Modification',
            deleted: 'Suppression',
            restored: 'Restauration',
        },
        model: {
            Burn: 'Brûlage',
            Contact: 'Contact',
            Declaration: 'Déclaration',
        },
        after_values: 'Détail',
        commune: {
            name: 'Commune',
        },
        communes: {
            id: 'Communes',
        },
    },
    'geometry_type_text': {
        Polygon: 'une zone de chantier',
        MultiPolygon: 'une ou plusieurs zones de chantier',
        Point: 'un point',
        MultiPoint: 'un ou plusieurs points',
    },
}
