<script lang="ts">
import DeclarationsTypesBar from '../components/dashboard/Statistics/DeclarationsTypesBar.vue'
import DeclarationsTypesDonut from '../components/dashboard/Statistics/DeclarationsTypesDoughnut.vue'
import BasicDoughnut from '../components/dashboard/Statistics/BasicDoughnut.vue'

export default {
    name: 'Dashboard',
    components: {
        DeclarationsTypesBar,
        DeclarationsTypesDonut,
        BasicDoughnut,
    },
    setup() {
        useHead({
            title: 'Serpic | Tableau de bord',
            meta: [
                { name: 'description', content: 'tableau de bord' },
            ],
        })
        definePageMeta({
            middleware: ['auth', 'has-territory'],
        })
    },

    data() {
        return {
            requestName: 'dashboard',
            loading: true,
            seasons: [],
            seasonShown: null,
            res: {},
        }
    },
    mounted() {
        this.initSeasonFilter()
    },
    methods: {
        initSeasonFilter() {
            this.$api.getData({
                url: `seasons`,
            }).then((res) => {
                this.seasons = res.data
                this.loadData()
            })
        },
        loadData() {
            this.loading = true
            if (!this.seasonShown) {
                this.seasonShown = this.seasons.find(season => season.active).k
            }
            const query = this.seasonShown ? `?seasons_ids=[${this.seasonShown}]` : '' // WE CAN MULTIPLE SEASON BUT NEED TO ADAPT CHARTS TO COMPARE COLORS

            this.$api.setRequest({
                url: `${this.requestName}${query}`,
                method: 'GET',
            }).then((res) => {
                this.res = res
                this.loading = false
            })
        },

    },
}
</script>

<template>
    <div v-if="$currentUser().k && $currentUser().role !== 'declarant'">
        <v-autocomplete
            v-model="seasonShown"
            label="Saison affichée"
            item-title="name"
            item-value="k"
            variant="outlined"
            density="compact"
            bg-color="white"
            :items="seasons"
            @update:model-value="loadData()"
        />

        <v-row v-if="res?.declaration_types" class="pa-2">
            <DashboardCardGraph
                :loading="loading"
                :stats="res"
                data-key="declaration_types"
            >
                <template #header>
                    Types de déclarations
                </template>
                <template #graph>
                    <DeclarationsTypesDonut :types="res.declaration_types" :season-shown />
                </template>
            </DashboardCardGraph>
            <DashboardCardGraph
                :loading="loading"
                :stats="res"
                data-key="declaration_types"
            >
                <template #header>
                    Types de déclarations par mois
                </template>
                <template #graph>
                    <DeclarationsTypesBar :types="res.declaration_types" />
                </template>
            </DashboardCardGraph>

            <DashboardCardGraph
                v-for="graph in Object.keys(res).filter(key => key !== 'declaration_types')"
                :key="graph"
                :loading="loading"
                :stats="res"
                :data-key="graph"
            >
                <template #graph>
                    <BasicDoughnut :graph-key="graph" :data="res[graph]" translation-name="declarations" :season-shown />
                </template>
            </DashboardCardGraph>
        </v-row>
    </div>
</template>
